body {
    --primary             : #40738C;
    --background          : #transparent;
    --background-dim      : #transparent;
    --background-dim-light: #f9f9f9;
    --primary-foreground  : #ffffff;
    --foreground          : #161616;
    --base-unit           : 8px;
    --font-family         : Roboto, sans-serif;
}

.sd-page {
    padding-top: 0px !important;
}

.sd-element {
    box-shadow: none !important;
}

.sd-btn {
    background-color: #41748D;
    color           : #ffffff;
}

.sd-navigation__prev-btn {
    background-color: #ffffff;
    color           : #41748D;
    border          : 1px solid #40738C;
}

.sd-footer {
    display        : flex;
    align-items    : flex-end;
    justify-content: flex-end;
}

.sd-element__title span {
    font-size: 20px !important;
}

.sd-question__description {
    font-size: 14px !important;
}

.navigation-block {
    margin: 10px;
}

.navigation-text {
    padding: 8px;
}

.navigation-button {
    background-color  : transparent;
    padding           : 8px;
    margin            : 6px 2px;
    border            : 2px solid #40738C;
    border-radius     : 5px;
    -webkit-transition: all 0.15s ease-in-out;
    transition        : all 0.15s ease-in-out;
    color             : #40738C;
}

.navigation-button:hover {
    box-shadow: 0 0 2px 0 #40738C inset, 0 0 4px 2px #40738C;
}

.navigation-page-selector {
    width        : 90px;
    box-sizing   : border-box;
    border-radius: 2px;
    height       : 34px;
    line-height  : 34px;
    background   : #fff;
    outline      : 1px solid #d4d4d4;
    text-align   : left;
    border       : none;
    padding      : 0 5px;
}

.navigation-page-selector:focus {
    outline: 1px solid #40738C;
}